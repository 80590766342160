<script setup lang="ts">
import IconCross from "~/assets/img/icons/icon-cross.svg";
const EVENT_KEYDOWN = "Escape";

defineProps<{ open: boolean | Ref<boolean> }>();

const emit = defineEmits(["close"]);

const handleClose = () => emit("close");

const handleKeyDown = (e: KeyboardEvent) => {
  if (e && e.key === EVENT_KEYDOWN) {
    handleClose();
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
  document.removeEventListener("keydown", handleClose);
});
</script>

<template>
  <Transition name="dialog-overlay">
    <div v-if="isRef(open) ? open.value : open" class="c-dialog-overlay">
      <dialog class="c-dialog-box" :class="$attrs.class">
        <button class="c-dialog-box__close" @click="$emit('close')">
          <slot name="icon-close">
            <IconCross />
          </slot>
        </button>
        <div v-if="$slots.video" class="c-dialog-box__video">
          <slot name="video"></slot>
        </div>

        <figure v-if="$slots.image" role="none" class="c-dialog-box__image">
          <slot name="image"></slot>
        </figure>
        <h2 v-if="$slots.title" class="c-dialog-box__title">
          <slot name="title"></slot>
        </h2>
        <p v-if="$slots.description" class="c-dialog-box__description">
          <slot name="description"></slot>
        </p>

        <div class="c-dialog-box__body">
          <slot />
        </div>
        <div v-if="$slots.footer" class="c-dialog-box__footer">
          <slot name="footer"></slot>
        </div>
      </dialog>
    </div>
  </Transition>
</template>

<style lang="scss">
.c-dialog-overlay {
  align-items: center;
  justify-content: center;
  position: fixed;
  border: 32px solid transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: map-get($z-index, "dialog"); // Ensure to be above navigation
  background: rgba(0, 0, 0, 0.5);
  display: flex;
}

.c-dialog-box {
  --_dialog-box-padding-x: 16px;
  --_dialog-box-padding-y: 16px;
  --_dialog-box-border-radius: 3px;

  position: relative;
  display: grid;
  grid-auto-rows: min-content;
  text-align: center;
  padding: var(--_dialog-box-padding-y) var(--_dialog-box-padding-x);
  border-radius: var(--_dialog-box-border-radius);
  border: 0;
  color: white;
  max-width: 950px;
  width: 100%;
  transform: translate3d(0, 0, 0);
  opacity: 1;

  &__close {
    --_close-size: 32px;
    --_close-position: calc(calc(var(--_close-size) / 2) * -1);

    position: absolute;
    background-color: $grey-light;
    color: $grey-medium;
    width: var(--_close-size);
    height: var(--_close-size);
    top: var(--_close-position);
    right: var(--_close-position);
    border-radius: 50%;
    transition:
      color 250ms ease-in,
      background-color 250ms ease-in;

    &:hover,
    &:focus {
      background: darken($grey-light, 5%);
      color: darken($grey-medium, 15%);
    }

    svg {
      margin: 0;
    }
  }
}

.dialog-overlay-enter-active,
.dialog-overlay-leave-active {
  transition: background-color 250ms ease-in;

  .c-dialog-box {
    transition:
      transform 200ms ease-in,
      opacity 250ms ease-in;
  }
}

.dialog-overlay-enter-from,
.dialog-overlay-leave-to {
  background: rgba(0, 0, 0, 0);

  .c-dialog-box {
    transform: translate3d(0, 10px, 0);
    opacity: 0;
  }
}
</style>
